<script>
  import ContentContainer from "@objects/ContentContainer.svelte"
  import Wrap from "@objects/Wrap.svelte"
  import { merchant } from "@store/index.js"
  import route from "@helpers/route"
  import Icon from "@components/Icon.svelte"
  import Combomark from "@components/logos/SideDoor/Combomark.svelte"
  import Link from "@components/Link.svelte"
  import i18n from "@store/i18n"

  export { className as class }
  let className = ""
</script>

<footer class="bg-themebg-50 {className}">
  <Wrap class="py-12 md:py-16">
    <ContentContainer>
      <h2 class="text-2xl">
        <Link
          type="none"
          external={!!$merchant.theme.logo_url_override}
          href={$merchant.theme.logo_url_override || route("shop.home")}>{$merchant.name}</Link
        >
      </h2>

      <div class="flex flex-col sm:flex-row gap-4 sm:gap-8 md:gap-20 mt-4 opacity-80">
        <div class="flex flex-col gap-2 text-sm">
          {#if $merchant.public_contact_info?.email}
            <Link type="none" class="flex gap-2" external href="mailto:{$merchant.public_contact_info.email}">
              <Icon name="mail" weight="line" />
              <p>{$merchant.public_contact_info.email}</p>
            </Link>
          {/if}

          {#if $merchant.public_contact_info?.phone_number}
            <Link type="none" class="flex gap-2" external href="tel:{$merchant.public_contact_info.phone_number}">
              <Icon name="phone" weight="line" />
              <p>{$merchant.public_contact_info.phone_number}</p>
            </Link>
          {/if}
        </div>

        {#if $merchant.public_contact_info?.address}
          <div>
            <p class="text-sm leading-[1.85]">
              {$merchant.public_contact_info.address.line1}<br />
              {$merchant.public_contact_info.address.city}
              , {$merchant.public_contact_info.address.region}
              {$merchant.public_contact_info.address.postal_code}<br />
            </p>
          </div>
        {/if}
      </div>

      {#if $merchant.public_contact_info?.links && $merchant.public_contact_info.links.length > 0}
        <div class="flex flex-wrap mt-2">
          {#each $merchant.public_contact_info.links as link (link.id)}
            <Link type="none" external href={link.url} class="underline py-2 pr-6 text-sm font-semibold"
              >{link.title}</Link
            >
          {/each}
        </div>
      {/if}

      <div class="flex">
        <Link external type="none" class="flex gap-1 items-center mt-6" target="_blank" href="https://onsidedoor.com">
          <span class="text-xs uppercase opacity-40">{$i18n.t("layouts.AppFooter.poweredBy")}</span>
          <Combomark class="h-3.5 opacity-60" />
        </Link>
      </div>
    </ContentContainer>
  </Wrap>
</footer>
