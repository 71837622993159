import i18next from "i18next"
import { createI18nStore } from "svelte-i18next"

// JSON imports work, but don't support interpolation checks.
// See https://github.com/microsoft/TypeScript/issues/32063
import enTranslation from "../translations/en/translation"

export const resources = {
  en: {
    translation: enTranslation,
  },
}

i18next.init({
  lng: "en",
  resources,
  interpolation: {
    // Not needed for Svelte as it escapes by default
    escapeValue: false,
  },
})

const i18n = createI18nStore(i18next)
export default i18n
