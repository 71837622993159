import axios from "axios"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import Affirm from "./services/Affirm"
import Analytics from "./services/Analytics"
import Front from "./services/Front"
import Sentry from "./services/Sentry"
import { createInertiaApp } from "@inertiajs/svelte"
import App from "@layouts/App.svelte"

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

Affirm.install()
Analytics.install()
Front.install()
Sentry.install()

// Initialize global js-video-url-parser providers
import "js-video-url-parser/lib/provider/youtube"
import "js-video-url-parser/lib/provider/vimeo"

// Register Swiper custom elements
// https://swiperjs.com/element
import { register as registerSwiper } from "swiper/element/bundle"

registerSwiper()

// https://vitejs.dev/guide/build.html#load-error-handling
window.addEventListener("vite:preloadError", (event) => {
  event.preventDefault()
  window.location.reload()
})

// @ts-expect-error
if (!import.meta.env.PROD) {
  // Nasty hack to silence harmless warnings the user can do nothing about.
  // see: https://github.com/westacks/inertia-svelte/issues/2
  // see: https://github.com/sveltejs/kit/commit/00bf4c12e22b2cae9ab16b87bcf015f6ef1f9dbd
  const warn = console.warn
  console.warn = (...args) => {
    if (args.length === 1 && /was created (with unknown|without expected) prop/.test(args[0])) {
      return
    }
    if (args.length === 1 && /received an unexpected slot/.test(args[0])) {
      return
    }
    warn(...args)
  }
}

function resolvePage(name: string) {
  name = name.replace(".", "/")

  if (name.includes("::")) {
    let [namespace, path] = name.split("::")

    return resolvePageComponent(
      `../../${namespace}/resources/pages/${path}.svelte`,
      // @ts-expect-error
      import.meta.glob("../../**/*.svelte"),
    )
  }

  // @ts-expect-error
  return resolvePageComponent(`../pages/${name}.svelte`, import.meta.glob("../pages/**/*.svelte"))
}

createInertiaApp({
  resolve: async (name: string) => {
    let page = await resolvePage(name)
    // @ts-expect-error
    return { default: page.default, layout: page.layout || App }
  },

  setup({ el, App, props }: { el: unknown; App: unknown; props: unknown }) {
    // @ts-expect-error
    new App({ target: el, props })
  },
})
