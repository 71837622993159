const translation = {
  components: {},

  layouts: {
    AppFooter: {
      poweredBy: "Powered by",
    },
    AppHeader: {
      showPages: "Show pages",
      cartItemCount_one: "{{count}} item in your cart",
      cartItemCount_other: "{{count}} items in your cart",
      signIn: "Sign in",
      signOut: "Sign out",
      managePaymentMethods: "Manage payment methods",
      myOrders: "My orders",
    },
  },

  pages: {},
} as const

export default translation
