<script lang="ts">
  import route from "@helpers/route"
  import plural from "@helpers/plural"
  import Link from "@components/Link.svelte"
  import { auth, merchant, totalCartItems } from "@store"
  import Branding from "@components/AppHeader/Branding.svelte"
  import Wrap from "@objects/Wrap.svelte"
  import ContentContainer from "@objects/ContentContainer.svelte"
  import Pill from "@components/Pill.svelte"
  import Snap from "@objects/Snap.svelte"
  import { page, router } from "@inertiajs/svelte"
  import Dropdown from "@components/Dropdown.svelte"
  import { signOut } from "@helpers/models/customer"
  import Icon from "@components/Icon.svelte"
  import i18n from "@store/i18n"

  export { className as class }

  let className = ""

  $: designerSitePageLinks = (
    $page.props.designer_site_page_links as { id: string; title: string; path: string }[]
  ).filter((dspLink) => {
    return dspLink.path !== "/"
  })

  totalCartItems.fetch()

  router.on("navigate", () => {
    totalCartItems.fetch()
  })
</script>

<header class="{className} py-8">
  <Wrap>
    <ContentContainer class="flex items-center">
      <Branding merchant={$merchant} />

      <nav class="ml-auto flex gap-4 sm:gap-6 items-center">
        {#if designerSitePageLinks.length > 0}
          <Dropdown>
            <svelte:fragment slot="trigger" let:toggle>
              <button type="button" on:click|preventDefault={toggle}>
                <Icon name="menu" weight="line" class="text-lg" />
                <span class="sr-only">{$i18n.t("layouts.AppHeader.showPages")}</span>
              </button>
            </svelte:fragment>

            <svelte:fragment slot="menu" let:close>
              <nav class="w-[240px] bg-white p-2 shadow-lg">
                <ul>
                  {#each designerSitePageLinks as dspLink}
                    {@const pageUrl = route("shop.designer-site-page.show", dspLink.path.substring(1))}

                    <li class:font-bold={window.location.href.indexOf(pageUrl) === 0}>
                      <Link
                        href={pageUrl}
                        on:click={close}
                        class="block px-4 py-1.5 w-full text-left text-stroke bg-canvas hover:bg-themebg-100 focus:bg-themebg-100"
                      >
                        {dspLink.title}
                      </Link>
                    </li>
                  {/each}
                </ul>
              </nav>
            </svelte:fragment>
          </Dropdown>
        {/if}

        <Link href={route("shop.checkout.cart.create")} class="[ block relative top-[3px] ]">
          <Snap>
            <span slot="badge">
              {#if $totalCartItems !== 0}
                <Pill
                  type="primary"
                  size="tiny"
                  class="[ flex items-center justify-center -translate-y-2/3 translate-x-1/2 ]"
                >
                  {$totalCartItems}
                </Pill>
              {/if}
            </span>

            <span class="sr-only">{$i18n.t("layouts.AppHeader.cartItemCount", { count: $totalCartItems })}</span>

            <svg
              class="[ h-4 w-4 relative ]"
              viewBox="0 0 24 24"
              height="24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <g transform="matrix(1.7142857142857142,0,0,1.7142857142857142,0,0)">
                <path
                  d="M13.359,6.624A1,1,0,0,0,12.367,5.5H1.633A1,1,0,0,0,.641,6.624l.75,6a1,1,0,0,0,.992.876h9.234a1,1,0,0,0,.992-.876Z"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.5,5.5V5a4.5,4.5,0,0,1,9,0v.5"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M5 8.5L5 10.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 8.5L9 10.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
          </Snap>
        </Link>

        {#if $auth.is_signed_in}
          <Dropdown>
            <svelte:fragment slot="trigger" let:toggle>
              <button type="button" on:click|preventDefault={toggle}>
                <Icon name="user" weight="line" class="text-lg" />
                <span class="sr-only">{$auth.user.full_name}</span>
              </button>
            </svelte:fragment>
            <svelte:fragment slot="menu" let:close>
              <nav class="w-[240px] bg-white p-2 shadow-lg">
                <Link
                  on:click={close}
                  class="block px-4 py-1.5 w-full text-left text-stroke bg-canvas hover:bg-themebg-100 focus:bg-themebg-100"
                  href={route("shop.orders.index")}
                >
                  {$i18n.t("layouts.AppHeader.myOrders")}
                </Link>
                <Link
                  external
                  forceSelf
                  class="block px-4 py-1.5 w-full text-left text-stroke bg-canvas hover:bg-themebg-100 focus:bg-themebg-100"
                  href={route("shop.account.payment-methods.edit")}
                >
                  {$i18n.t("layouts.AppHeader.managePaymentMethods")}
                </Link>
                <hr class="my-2 opacity-10" />
                <button
                  class="block px-4 py-1.5 w-full text-left text-stroke bg-canvas hover:bg-themebg-100 focus:bg-themebg-100"
                  type="button"
                  on:click|preventDefault={signOut}>{$i18n.t("layouts.AppHeader.signOut")}</button
                >
              </nav>
            </svelte:fragment>
          </Dropdown>
        {:else}
          <Link type="none" href="{route('shop.signin.create')}?intended={window.location.href}"
            >{$i18n.t("layouts.AppHeader.signIn")}</Link
          >
        {/if}
      </nav>
    </ContentContainer>
  </Wrap>
</header>
